<template>
  <design-page class="DesignPagePromotions">
    <div class="BodyCatalogueSolutions">
      <div class="CenterContainerCatalogue ContainerSpace">
        <div class="ContainerGridCatalogue">
          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>LONAS</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section
                class="SliderCards"
                v-for="(card, index) in CardLonas"
                :key="index"
              >
                <cards-products-lonas
                  class="CardsLonas"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-lonas>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>VINIL</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardVinil" :key="index">
                <cards-products-vinil
                  class="CardsVinil"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-vinil>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>TELAS</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardTelas" :key="index">
                <cards-products-telas
                  class="CardsTelas"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-telas>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>FLOOR GRAPHIC</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardFloorGraphic" :key="index">
                <cards-products-floor-graphic
                  class="CardsFloorGraphic"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-floor-graphic>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>IMAN</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardIman" :key="index">
                <cards-products-iman
                  class="CardsIman"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-iman>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>BOND</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardBond" :key="index">
                <cards-products-bond
                  class="CardsBond"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-bond>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>PERFORADO</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardPerforado" :key="index">
                <cards-products-perforado
                  class="CardsPerforado"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-perforado>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>TEXTIL</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardTextil" :key="index">
                <cards-products-textil
                  class="CardsTextil"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-textil>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <div class="TitleHeaderCatalogue Title">
                <h1>ADHERIBLE</h1>
              </div>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardAdherible" :key="index">
                <cards-products-adherible
                  class="CardsAdherible"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-adherible>
              </section>
            </div>
          </div>

          <div class="ContainerGridCategories">
            <div class="LeftContainerTitleHeader">
              <section class="TitleHeaderCatalogue Title">
                <h1>TAZAS</h1>
              </section>
            </div>
            <div class="RightContainer">
              <section v-for="(card, index) in CardTazas" :key="index">
                <cards-products-tazas
                  class="CardsLonas"
                  :tittle="card.tittle"
                  :description="card.description"
                  :imageUrl="card.imgurl"
                ></cards-products-tazas>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import DesignPage from "../components/DesignPage.vue";
import CardsProductsLonas from "../components/CardsProductsLonas.vue";
import CardsProductsTelas from "../components/CardsProductsTelas.vue";
import CardsProductsVinil from "./CardsProductsVinil.vue";
import CardsProductsFloorGraphic from "./CardsProductsFloorGraphic.vue";
import CardsProductsIman from "./CardsProductsIman.vue";
import CardsProductsBond from "./CardsProductsBond.vue";
import CardsProductsPerforado from "./CardsProductsPerforado.vue";
import CardsProductsTextil from "./CardsProductsTextil.vue";
import CardsProductsAdherible from "./CardsProductsAdherible.vue";
import CardsProductsTazas from "./CardsProductsTazas.vue";

export default {
  name: "Catalogue",
  components: {
    DesignPage,
    CardsProductsLonas,
    CardsProductsTelas,
    CardsProductsVinil,
    CardsProductsFloorGraphic,
    CardsProductsIman,
    CardsProductsBond,
    CardsProductsPerforado,
    CardsProductsTextil,
    CardsProductsAdherible,
    CardsProductsTazas,
  },
  data() {
    return {
      CardLonas: [
        {
          
          id: 1,
          tittle: "SENCILLA",
          description: "Esta es nuestra lona mas basica ¡Contactanos!",
          imgurl: "1.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADA",
          description: "Esta es la mejor, a un precio accesible ¡Vamos!",
          imgurl: "1.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Esta es nuestra mejor lona ¡Llama Ahora!",
          imgurl: "1.png",
        },
        {
          id: 4,
          tittle: "ESPECIAL",
          description: "Esta es nuestra mejor lona ¡Llama Ahora!",
          imgurl: "1.png",
        },
      ],
      CardVinil: [
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Descripcion 1",
          imgurl: "2.jpg",
        },
        {
          id: 2,
          tittle: "ESTAMPADA",
          description: "Descripcion 2",
          imgurl: "2.jpg",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Descripcion 3",
          imgurl: "2.jpg",
        },
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Descripcion 1",
          imgurl: "2.jpg",
        },
      ],
      CardTelas: [
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "3.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADA",
          description: "Inserte aqui la descripcion",
          imgurl: "3.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "3.png",
        },
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "3.png",
        },
      ],
      CardFloorGraphic: [
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "4.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "4.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "4.png",
        },
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "4.png",
        },
      ],
      CardIman: [
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "5.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "5.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "5.png",
        },
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "5.png",
        },
      ],
      CardBond: [
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "6.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "6.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "6.png",
        },
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "6.png",
        },
      ],
      CardPerforado: [
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "7.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "7.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "7.png",
        },
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "7.png",
        },
      ],
      CardTextil: [
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "10.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "10.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "10.png",
        },
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "10.png",
        },
      ],
      CardAdherible: [
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "Hules_Colores.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADO",
          description: "Inserte aqui la descripcion",
          imgurl: "Hules_Colores.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "Hules_Colores.png",
        },
        {
          id: 1,
          tittle: "SENCILLO",
          description: "Inserte aqui la descripcion",
          imgurl: "Hules_Colores.png",
        },
      ],
      CardTazas: [
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "9.png",
        },
        {
          id: 2,
          tittle: "ESTAMPADA",
          description: "Inserte aqui la descripcion",
          imgurl: "9.png",
        },
        {
          id: 3,
          tittle: "ESPECIAL",
          description: "Inserte aqui la descripcion",
          imgurl: "9.png",
        },
        {
          id: 1,
          tittle: "SENCILLA",
          description: "Inserte aqui la descripcion",
          imgurl: "9.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.BodyCatalogueSolutions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 550vh;
}
.CenterContainerCatalogue {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 1%;
}
.TitleHeaderCatalogue {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: bold;
  font-weight: bold;
  font-size: 1em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
}
.Title h1 {
  text-align: center;
  color: #dc9942;
}
.ContainerGridCatalogue {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.ContainerGridCategories {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.LeftContainerTitleHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 20%;
  height: 100%;
}
.RightContainer {
  display: flex;
  width: 80%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media (min-width: 200px) and (max-width: 300px) {
  .BodyCatalogueSolutions {
    flex-direction: column;
    width: 100%;
  }
  .CenterContainerCatalogue {
    top: 1.5%;
    flex-direction: column;
    width: 100%;
  }
  .ContainerGridCatalogue {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .ContainerGridCategories {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .LeftContainerTitleHeader {
    width: 100%;
    height: 20%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .RightContainer {
    width: 100%;
    height: 90%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .TitleHeaderCatalogue h1{
    position: relative;
    font-size: 1.5em;
    left: -6%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
.BodyCatalogueSolutions {
    flex-direction: column;
    width: 100%;
  }
  .CenterContainerCatalogue {
    top: 1.5%;
    flex-direction: column;
    width: 100%;
  }
  .ContainerGridCatalogue {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .ContainerGridCategories {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .LeftContainerTitleHeader {
    width: 100%;
    height: 20%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .RightContainer {
    width: 100%;
    height: 90%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .TitleHeaderCatalogue h1{
    position: relative;
    font-size: 1.5em;  
  }
}
@media (min-width: 480px) and (max-width: 660px) {
.BodyCatalogueSolutions {
    flex-direction: column;
    width: 100%;
  }
  .CenterContainerCatalogue {
    top: 1.5%;
    flex-direction: column;
    width: 100%;
  }
  .ContainerGridCatalogue {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .ContainerGridCategories {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .LeftContainerTitleHeader {
    width: 100%;
    height: 20%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .RightContainer {
    width: 100%;
    height: 90%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .TitleHeaderCatalogue h1{
    position: relative;
    font-size: 1.5em;  
  }
}
@media (min-width: 660px) and (max-width: 980px) {
.BodyCatalogueSolutions {
    flex-direction: column;
    width: 100%;
  }
  .CenterContainerCatalogue {
    top: 1.5%;
    flex-direction: column;
    width: 100%;
  }
  .ContainerGridCatalogue {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .ContainerGridCategories {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .LeftContainerTitleHeader {
    width: 100%;
    height: 20%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .RightContainer {
    width: 100%;
    height: 90%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .TitleHeaderCatalogue h1{
    position: relative;
    font-size: 1.5em;  
  }
}
@media (min-width: 980px) and (max-width: 1024px) {
.BodyCatalogueSolutions {
    flex-direction: column;
    width: 100%;
  }
  .CenterContainerCatalogue {
    top: 1.5%;
    flex-direction: column;
    width: 100%;
  }
  .ContainerGridCatalogue {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .ContainerGridCategories {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .LeftContainerTitleHeader {
    width: 100%;
    height: 20%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .RightContainer {
    width: 100%;
    height: 90%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .TitleHeaderCatalogue h1{
    position: relative;
    font-size: 1.5em;  
  }
}
</style>
