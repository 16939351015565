<template>
  <design-page class="DesignPageMainMenu">
    <div class="BodyMainMenuSolutions">
      <div class="CenterContainerMainMenu">
        <div class="ContainerLeftMainMenu">
          <div>
            <h1>¡Somos Los Mejores!</h1>
            <h2>Las mejores impresiones de todo tipo solo para ti</h2>
            <router-link to="Categories">
              <button class="Beging-Button">
                ¡Vamos Alla!
              </button>
            </router-link>
          </div>
        </div>
        <div class="ContainerRightMainMenu">
          <div id="slider">
            <figure>
              <img src="../assets/img/1.png" />
              <img src="../assets/img/2.jpg" />
              <img src="../assets/img/3.png" />
              <img src="../assets/img/4.png" />
              <img src="../assets/img/5.png" />
              <img src="../assets/img/6.png" />
              <img src="../assets/img/7.png" />
              <img src="../assets/img/8.png" />
              <img src="../assets/img/9.png" />
              <img src="../assets/img/10.png" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import DesignPage from "../components/DesignPage";
export default {
  name: "MainMenu",
  components: {
    DesignPage,
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.BodyMainMenuSolutions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
.CenterContainerMainMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 60%;
}
.ContainerLeftMainMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 60%;
}
.ContainerLeftMainMenu h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 3.9em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.ContainerLeftMainMenu h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.Beging-Button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  left: 33%;
  border: none;
  outline: none;
  margin-top: 5%;
  background-color: #2d3436;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  border-radius: 100px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
}
.Beging-Button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
  background-image: linear-gradient(to right, #f0f0f0, #f0f0f0);
  transition: all 0.55s;
  z-index: -1;
}
.Beging-Button:hover {
  transform: scale(1.1);
  color: black;
}
.Beging-Button:hover::before {
  height: 100%;
}
.ContainerRightMainMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}
#slider {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#slider figure {
  position: relative;
  width: 1000%; /* 100% for each picture, 10 pictures = 1000% */
  height: 100%;
  margin: 0;
  left: 0;
  animation: 40s slider infinite;
}
#slider figure img {
  width: 10%; /* 100 / 10 pictures = 10% */
  height: 100px; /* to set a maximum height  */
  float: left;
  /*  next 2 to continue responsive with the maximum height  */
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
@keyframes slider {
  0% {
    left: 0;
  } /* you must divide 100% fot the amout of images you have */
  7% {
    left: 0%;
  } /* these intermediate steps (7%, 17%, 27%, ... will make the transition speed faster */
  10% {
    left: -100%;
  } /* because my total width: 1000% (10x 100% for 10 images), I will use 10% for each image */
  17% {
    left: -100%;
  }
  20% {
    left: -200%;
  }
  27% {
    left: -200%;
  }
  30% {
    left: -300%;
  }
  37% {
    left: -300%;
  }
  40% {
    left: -400%;
  }
  47% {
    left: -400%;
  }
  50% {
    left: -500%;
  }
  57% {
    left: -500%;
  }
  60% {
    left: -600%;
  }
  67% {
    left: -600%;
  }
  70% {
    left: -700%;
  }
  77% {
    left: -700%;
  }
  80% {
    left: -800%;
  }
  87% {
    left: -800%;
  }
  90% {
    left: -900%;
  }
  100% {
    left: -900%;
  }
}
@media (min-width: 200px) and (max-width: 300px) {
  //SmartPhones Pequeños
  .CenterContainerMainMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
    left: -10px;
  }
  .ContainerLeftMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 2.7em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 1em;
  }
  .Beging-Button {
    width: 65%;
    height: 30%;
    left: 20%;
    top: 245px;
  }
  .ContainerRightMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #slider {
    width: 85%;
    height: 85%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  //SmartPhones Promedio
  .CenterContainerMainMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
    top: -25px;
  }
  .ContainerLeftMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 2.7em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 1em;
  }
  .Beging-Button {
    width: 65%;
    height: 30%;
    left: 20%;
    top: 350px;
  }
  .ContainerRightMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
    width: 100%;
  }
  #slider {
    width: 75%;
    height: 75%;
  }
}
@media (min-width: 480px) and (max-width: 660px) {
  //SmartPhones Grandes
  .CenterContainerMainMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
    top: -15px;
  }
  .ContainerLeftMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 3.1em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 1.2em;
  }
  .Beging-Button {
    width: 65%;
    height: 30%;
    left: 20%;
    line-height: 60px;
    top: 330px;
  }
  .ContainerRightMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #slider {
    width: 70%;
    height: 70%;
  }
}
@media (min-width: 660px) and (max-width: 980px) {
  //IPad/Tablets Promedio
  .CenterContainerMainMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
  }
  .ContainerLeftMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 4.1em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 1.5em;
  }
  .Beging-Button {
    width: 65%;
    height: 30%;
    left: 20%;
    line-height: 60px;
    top: 450px;
  }
  .ContainerRightMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
    width: 100%;
  }
  #slider {
    width: 80%;
    height: 80%;
  }
}
@media (min-width: 980px) and (max-width: 1024px) {
  //Ipad/Tablets Grandes
  .CenterContainerMainMenu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
  }
  .ContainerLeftMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 5.6em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 1.7em;
  }
  .Beging-Button {
    width: 65%;
    height: 30%;
    left: 20%;
    line-height: 20px;
    top: 620px;
  }
  .ContainerRightMainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
    width: 100%;
  }
  #slider {
    width: 80%;
    height: 80%;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  //Ajuste de boton MotoG4 y otros
  .Beging-Button {
    top: 275px;
  }
}
@media (min-width: 411px) and (max-width: 411px) {
  //Ajuste de boton Pixel2 y otros
  .Beging-Button {
    top: 300px;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  //IPhone 5/SE, resolucion especifica.
  .CenterContainerMainMenu {
    width: 90%;
    height: 50%;
    top: 0;
  }
  .ContainerLeftMainMenu {
    height: 40%;
    width: 100%;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 1.8em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 0.7em;
  }
  .Beging-Button {
    height: 35%;
    top: 220px;
    line-height: 80px;
  }
  .ContainerRightMainMenu {
    height: 60%;
    width: 100%;
  }
  #slider {
    width: 75%;
    height: 75%;
  }
}
@media (min-height: 667px) and (max-height: 667px) {
  //IPhone 6,7,8, resolucion especifica
  .CenterContainerMainMenu {
    height: 70%;
    top: 0;
  }
  .ContainerLeftMainMenu h1 {
    font-size: 2.1em;
  }
  .ContainerLeftMainMenu h2 {
    font-size: 0.9em;
  }
  .Beging-Button {
    width: 65%;
    height: 50%;
    left: 20%;
    top: 315px;
  }
}
</style>
