<template>
  <div class="card" onclick="this.classList.toggle('expanded')">
    <img
      class="ImgTelas"
      :alt="titlle"
      :src="require(`../assets/img/${imageUrl}`)"
    />
    <div class="text1">
      <div class="text-content">
        <h1 class="title">{{ tittle }}</h1>
        <div class="body-text">{{ description }}</div>
      </div>
    </div>
    <svg
      class="chevron"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 35"
      width="30"
    >
      <path d="M5 30L50 5l45 25" fill="none" stroke="#000" stroke-width="5" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CardProductsTelas",
  props: {
    tittle: String,
    description: String, 
    imageUrl: String,
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:900');

.card {
  background: white;
  border-radius: 30px;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  cursor: pointer;
  height: 250px;
  margin: 20px;
  padding: 0 20px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0.025);
  text-align: center;
  transition: height 1000ms;
  width: 260px;
}
.card.expanded {
  height: 340px;
}
.ImgTelas {
  margin-top: 30px;
  transform: translateY(10px);
  transition: transform 1000ms;
}
.card.expanded .Telas {
  transform: translateY(0);
}
.text1 {
  clip-path: polygon(0% 100%, 0 -90%, 50% -5%, 100% -90%, 100% 100%);
  -webkit-clip-path: polygon(0% 100%, 0 -90%, 50% -5%, 100% -90%, 100% 100%);
  transition: clip-path 1000ms;
}
.card.expanded .text1 {
  clip-path: polygon(0% 100%, 0 -100%, 50% -15%, 100% -100%, 100% 100%);
  -webkit-clip-path: polygon(0% 100%, 0 -100%, 50% -15%, 100% -100%, 100% 100%);
}
.text2 {
  clip-path: polygon(0% 100%,0% -80%,15% -70%,17.23% -53.59%,23% -40%,27.23% -28.94%,35% -20%,41.09% -13.59%,50% -10%,58.91% -13.23%,65% -20%,72.77% -28.23%,77% -40%,82.77% -54.65%,85% -70%,100% -80%,100% 100%);
  -webkit-clip-path: polygon(0% 100%,0% -80%,15% -70%,17.23% -53.59%,23% -40%,27.23% -28.94%,35% -20%,41.09% -13.59%,50% -10%,58.91% -13.23%,65% -20%,72.77% -28.23%,77% -40%,82.77% -54.65%,85% -70%,100% -80%,100% 100%);
  transition: clip-path 1000ms;
}
.card.expanded .text2 {
  clip-path: polygon(0% 100%,0% -90%,15% -80%,17.23% -63.59%,23% -50%,27.23% -38.94%,35% -30%,41.09% -23.59%,50% -20%,58.91% -23.23%,65% -30%,72.77% -38.23%,77% -50%,82.77% -64.65%,85% -80%,100% -90%,100% 100%);
  -webkit-clip-path: polygon(0% 100%,0% -90%,15% -80%,17.23% -63.59%,23% -50%,27.23% -38.94%,35% -30%,41.09% -23.59%,50% -20%,58.91% -23.23%,65% -30%,72.77% -38.23%,77% -50%,82.77% -64.65%,85% -80%,100% -90%,100% 100%);
}
.text-content {
  transform: translateY(-160px);
  transition: transform 1000ms;
}
.card.expanded .text-content {
  transform: translateY(-15px);
}
.chevron {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 15px);
  transform-origin: 50%;
  transform: rotate(180deg);
  transition: transform 1000ms;
}
.card.expanded .chevron {
  transform: rotate(0deg);
}
.title {
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 900;
  margin: 20px 0 12px;
}
.body-text {
  font-size: 0.7em;
  padding: 0 20px;
}
@media (min-width: 200px) and (max-width: 300px){
  .card{
  height: 220px;
  padding: 5px 20px;
  transition: height 1000ms;
  width: 220px;
  }
  .card.expanded{
    height: 320px;
  }
}
@media (min-width: 300px) and (max-width: 480px){
  .card{
  height: 220px;
  padding: 5px 20px;
  transition: height 1000ms;
  width: 220px;
  }
  .card.expanded{
    height: 320px;
  }
}
@media (min-width: 480px) and (max-width: 660px){
  .card{
  height: 220px;
  padding: 0 20px;
  transition: height 1000ms;
  width: 220px;
  }
  .card.expanded{
    height: 295px;
  }
}
</style>
