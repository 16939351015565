<template>
  <section class="content">
      <div></div>
      <slot></slot>
  </section>
</template>

<script>
export default {
    name: "DesignPage",
    props: { 
    },
}
</script>

<style>

</style>